/* Loader.css */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


:root {
    --offset: 187;
    --duration: 1.4s;
}

.loader-container .spinner {
    animation: rotator var(--duration) linear infinite;
}

.loader-container .path {
    stroke-dasharray: var(--offset);
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash var(--duration) ease-in-out infinite,
        colors calc(var(--duration) * 4) ease-in-out infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes colors {
    0% {
        stroke: #4285F4;
    }

    25% {
        stroke: #DE3E35;
    }

    50% {
        stroke: #F7C223;
    }

    75% {
        stroke: #1B9A59;
    }

    100% {
        stroke: #4285F4;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: var(--offset);
    }

    50% {
        stroke-dashoffset: calc(var(--offset) / 4);
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: var(--offset);
        transform: rotate(450deg);
    }
}
