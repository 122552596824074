body {
    font-family: 'Arial', sans-serif;
}

.card {
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.card-body {
    padding: 20px;
}

.float-end {
    font-size: 14px;
}

.btn {
    font-size: 16px;
    min-width: 160px;
    padding: 12px;
    border-radius: 8px;
    transition: transform 0.5s ease-in-out;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover,
.btn-outline-primary:hover {
    transform: scale(1.1);
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 8px solid #007bff;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.alert-warning {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
    margin-top: -30px;
    margin-bottom: 30px;
}

.alert-warning h5 {
    margin-bottom: 10px;
    color: #721c24;
}

.alert-warning button {
    background-color: #c82333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.alert-warning button:hover {
    background-color: #dc3545;
}

.gradient-custom {
    background: linear-gradient(to right, #3931af, #00c6ff);
}

.card-custom {
    border-bottom-left-radius: 10% 50%;
    border-top-left-radius: 10% 50%;
    background-color: #f8f9fa;
}

.input-custom {
    background-color: white;
}

.white-text {
    color: #f0f0f0;
    font-weight: 100;
    font-size: 14px;
}

.back-button {
    background-color: #f0f0f0;
    font-weight: 700;
    color: black;
    margin-top: 50px;
}

.modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.rating-wrapper {
    align-self: auto;
    box-shadow: 7px 7px 25px rgba(198, 206, 237, .7),
        -7px -7px 35px rgba(255, 255, 255, .7),
        inset 0px 0px 4px rgba(255, 255, 255, .9),
        inset 7px 7px 15px rgba(198, 206, 237, .8);
    border-radius: 5rem;
    display: inline-flex;
    direction: rtl !important;
    padding: 0.5rem 2.5rem;
    margin-left: auto;
}

.rating-wrapper input {
    display: none;
}

.rating-wrapper label {
    color: #E1E6F6;
    cursor: pointer;
    display: inline-flex;
    font-size: 3rem;
    padding: 1rem .6rem;
    transition: color 0.5s;
}

.rating-wrapper svg {
    -webkit-text-fill-color: transparent;
    -webkit-filter: drop-shadow(4px 1px 6px rgba(198, 206, 237, 1));
    filter: drop-shadow(5px 1px 3px rgba(198, 206, 237, 1));
}

.rating-wrapper input {
    height: 100%;
    width: 100%;
}

.rating-wrapper label:hover,
.rating-wrapper label:hover~label,
.rating-wrapper input:checked~label {
    color: #34AC9E;
}

.star-rating {
    cursor: pointer;
    font-size: 24px;
    color: #ddd;
    transition: color 0.3s ease-in-out;
}

.star-rating.selected {
    color: #f37a27;
}

@media only screen and (max-width: 1024px) {
    .btn {
        min-width: 140px;
        padding: 10px;
    }

    .alert-warning {
        padding: 15px 15px 8px 15px;
    }

    .alert-warning h5 {
        margin-bottom: 8px;
    }

    .alert-warning button {
        padding: 8px 16px;
    }

    .back-button {
        margin-top: 30px;
    }
}

/* Add these styles to your existing style.css file */

/* General Styles */
body {
    font-family: 'Arial', sans-serif;
}

.bg-white {
    background-color: #fff;
}

.text-start {
    text-align: start !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-muted {
    color: #6c757d;
}

.text-primary {
    color: #007bff;
}

.text-secondary {
    color: #6c757d;
}

.text-dark {
    color: #343a40;
}

.text-success {
    color: #28a745;
}

.text-danger {
    color: #dc3545;
}

.text-light {
    color: #f8f9fa;
}

.text-center {
    text-align: center !important;
}

.text-primary {
    color: #007bff !important;
}

/* Button Styles */
.btn {
    border-radius: 8px;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
}

.btn-outline-primary {
    color: #007bff;
    border: 1px solid #007bff;
}

.btn-light {
    background-color: #f8f9fa;
    color: #343a40;
}

.btn-lg {
    padding: 10px 20px;
    font-size: 16px;
}

/* Modal Styles */
.modal-content {
    border-radius: 15px;
    border-top: 3px solid #f37a27;
    border-bottom: 3px solid #f37a27;
}

.modal-header {
    border-bottom: none !important;
}

/* Loader Styles */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 8px solid #007bff;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Order List Styles */
.order-list {
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

/* Rating Styles */
.rating-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star-rating {
    cursor: pointer;
    font-size: 24px;
    color: #ddd;
    transition: color 0.3s ease-in-out;
}

.star-rating.selected {
    color: #f37a27;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .btn-lg {
        font-size: 14px;
    }

    .modal-content {
        border-radius: 10px;
    }

    .order-list {
        margin-bottom: 15px;
    }

    .btn {
        margin-bottom: 0px;
        /* Adjust the padding for smaller screens */
    }

    .back-button {
        margin-top: 30px;
    }
}