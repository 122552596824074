.container-content {
    padding: 95px 70px 50px;
}


.compalinace-content {
    background-color: #f8f8f8;
    padding: 55px 70px 100px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.content-container {
    margin: 10px auto;
}

.content-head {
    font-size: 34px;
    font-weight: bold;
    color: #333;
}

.content-separator {
    height: 2px;
    background-color: #ccc;
    margin: 15px 0;
}

.updated-date {
    color: #666;
    font-weight: bold;
}

.content-text {
    color: #555;
    line-height: 1.6;
}

.list-item {
    margin-bottom: 8px;
}

/* Footer Section */
#footer {
    padding: 0px 0 50px;
}

.container-footer {
    padding: 0px 70px 0px;
    max-width: 1500px;
    margin: 100px auto 0;
}

.footer-about {
    margin-bottom: 15px;
}

.footer-about .logo {
    font-size: 24px;
    color: #001973;
}

.footer-about p {
    text-align: justify;
    color: #555;
    margin-top: 15px;
}

.social-links a {
    font-size: 18px;
    margin-right: 15px;
    color: #555;
    transition: color 0.3s ease-in-out;
}

.social-links a:hover {
    color: #001973;
}

/* Footer Links */
.footer-links h4 {
    font-size: 18px;
    color: #001973;
    margin-bottom: 20px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 8px;
}

.footer-links a {
    color: #555;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
    color: #001973;
}

/* Footer Contact */
.footer-contact h4 {
    font-size: 18px;
    color: #001973;
    margin-bottom: 20px;
}

.footer-contact p {
    color: #555;
    margin-bottom: 8px;
}

.footer-contact strong {
    color: #001973;
}

.footer-contact span {
    color: #555;
}