.shop-list-container-service {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 120px 150px 190px 150px;
}

.card-service {
    width: 300px;
    height: 150px;
    margin: 20px 10px;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.shop-card-service {
    cursor: pointer;
    background-color: #fff;
    transition: box-shadow 0.3s;
}

.shop-card-service:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-body-service {
    display: flex;
    flex-direction: column;
    padding: 15px;
    position: relative;
    z-index: 2;
}

.card-title-service {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #001973;
    transition: color 0.2s;
}

.card-title-service:hover {
    color: var(--color-primary);
}

.star-rating-service {
    color: #ffac00;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    z-index: 2;
}

.closed-message {
    font-size: 0.9rem;
    color: red;
    margin-top: 10px;
}

.animated-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ff9966, #ff5e62);
    opacity: 0.2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    animation: colorChange 3s infinite alternate;
}

@keyframes colorChange {
    0% {
        background: linear-gradient(to right, #ff9966, #ff5e62);
    }

    100% {
        background: linear-gradient(to right, #ff5e62, #ff9966);
    }
}