.datatable {
    height: 20%;
    width: 98%;

    .datatableTitle {
        width: 100%;
        font-size: 40px;
        font-weight: 500;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link {
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .cellAction {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .viewButton,
    .deleteButton {
        padding: 2px 5px;
        border-radius: 15px;
        cursor: pointer;
    }

    .viewButton {
        background-color: rgb(85, 85, 160);
        border: 1px dotted rgba(0, 0, 139, 0.596);
    }

    .deleteButton {
        background-color: rgb(155, 68, 85);
        border: 1px dotted rgba(220, 20, 60, 0.6);
    }

    .effect {
        text-align: center;
        display: inline-block;
        position: relative;
        text-decoration: none;
        color: #fff;
        text-transform: capitalize;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 15px;
        padding: 4px 0px;
        width: 87px;
        border-radius: 5px;
        overflow: hidden;
    }

    .effect.effect-2 {
        transition: all 0.2s linear 0s;
    }

    .effect.effect-2:before {
        content: "\f0a4";
        font-family: FontAwesome;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: -30px;
        width: 30px;
        height: 100%;
        background-color: rgba(#fff, 0.3);
        border-radius: 0px 6px 6px 0px;
        transition: all 0.2s linear 0s;
        text-align: center;
    }

    .effect.effect-2:hover {
        text-indent: -30px;
    }

    .effect.effect-2:hover:before {
        right: 0;
        text-indent: 0px;
    }
}

.prdcard {
    padding: 35px 40px 50px;
    margin-bottom: 40px;
    border: none !important;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    /* width: 100vh; */
    align-items: center;
}

.blue-text {
    color: #00BCD4
}

.form-control-label {
    margin-bottom: 0
}

input,
textarea,
button,
select {
    padding: 8px 15px;
    border-radius: 5px !important;
    margin: 10px 0px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 18px !important;
    font-weight: 300
}

input:focus,
textarea:focus,
select:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #00BCD4;
    outline-width: 0;
    font-weight: 400
}

.btn-block {
    text-transform: uppercase;
    font-size: 15px !important;
    font-weight: 400;
    height: 43px;
    cursor: pointer
}

.btn-block:hover {
    color: #fff !important
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}

.home {
    display: flex;
    margin-bottom: 150px;

    .homeContainer {
        width: 55%;
        flex: 6;

        .widgets,
        .charts {
            display: flex;
            padding: 20px;
            gap: 20px;
        }

        .charts {
            padding: 5px 20px;
        }

        .listContainer {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px;

            .listTitle {
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }
}

.get-in-touch {
    max-width: 800px;
    margin: auto;
    position: relative;

}

.get-in-touch .title {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 3.2em;
    line-height: 48px;
    padding-bottom: 48px;
    color: #5543ca;
    background: #5543ca;
    background: -moz-linear-gradient(left, #f4524d 0%, #5543ca 100%);
    background: -webkit-linear-gradient(left, #f4524d 0%, #5543ca 100%);
    background: linear-gradient(to right, #f4524d 0%, #5543ca 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}


.contact-form .form-field {
    position: relative;
    margin: 32px 0;
}

.contact-form .input-text {
    display: block;
    width: 100%;
    height: 36px;
    border-width: 0 0 2px 0;
    border-color: #5543ca;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
}

.contact-form .input-text:focus {
    outline: none;
}

.contact-form .input-text:focus+.label,
.contact-form .input-text.not-empty+.label {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
}

.contact-form .label {
    position: absolute;
    left: 20px;
    bottom: 11px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #5543ca;
    cursor: text;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out,
        -webkit-transform .2s ease-in-out;
}

.contact-form .submit-btn {
    display: inline-block;
    background-color: #000;
    background-image: linear-gradient(125deg, #a72879, #064497);
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    width: 200px;
    cursor: pointer;
}

.sidebar-shop-info {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0px auto 30px;
}

.sidebar-shop-name {
    font-size: 26px;
    color: #333;
    font-weight: bold;
}

.sidebar-shop-rating {
    margin-top: 2px;
}

.sidebar-star-rating i {
    font-size: 1px;
    margin-right: 4px;
}

.sidebar-rating-count {
    font-size: 16px;
    color: #666;
}

.product-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.product-name {
    font-weight: bold;
    margin-bottom: -5px;
}

.product-subtitle {
    color: #777;
    margin-bottom: 5px;
    /* Adjust color as needed */
}