.sidebar {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: auto;
    background-color: white;
    margin-bottom: 70px;
}

/* Top Section */
.sidebar-top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

/* Logo */
.sidebar-logo {
    font-size: 20px;
    font-weight: bold;
    color: #6439ff;
}

/* Horizontal Rule */
.sidebar-hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
}

/* Centerbar Section */
.sidebar-centerbar {
    padding-left: 0px;
}

/* List Title */
.sidebar-title {
    font-size: 10px;
    font-weight: bold;
    color: #999;
    margin-top: 15px;
    margin-bottom: 5px;
}

/* List Item */
.sidebar-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

/* List Item Hover */
.sidebar-item:hover {
    background-color: #ece8ff;
}

/* List Icon */
.sidebar-icon {
    font-size: 18px;
    color: #7451f8;
}

/* List Text */
.sidebar-text {
    font-size: 13px;
    font-weight: 600;
    color: #888;
    margin-left: 10px;
}

/* Color Option */
.sidebar-color-option {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #7451f8;
    cursor: pointer;
    margin: 5px;
}

/* Color Option 1 */
.sidebar-color-option:nth-child(1) {
    background-color: whitesmoke;
}

/* Color Option 2 */
.sidebar-color-option:nth-child(2) {
    background-color: #333;
}

/* Color Option 3 */
.sidebar-color-option:nth-child(3) {
    background-color: darkblue;
}