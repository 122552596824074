.shop-info {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 90px auto 30px;
}

.shop-name {
    font-size: 33px;
    color: #333;
    font-weight: bold;
}

.shop-rating {
    margin-top: 10px;
}

.star-rating i {
    font-size: 24px;
    margin-right: 4px;
}

.rating-count {
    font-size: 16px;
    color: #666;
}

.shop-location {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.location-icon {
    margin-right: 8px;
    color: #3ab757;
}

.location-link {
    font-size: 18px;
    color: #3ab757;
    text-decoration: none;
    transition: color 0.3s ease;
}

.location-link:hover {
    color: #248f3f;
}

.open-status {
    font-size: 14px;
    color: #9c9c9c;
}

.timing {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.menu-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 70px 150px 170px;
}

.menu-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.menu-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.menu-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.menu-item p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #666;
}

.dietary-preference {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.add-to-cart-container {
    display: flex;
    align-items: center;
}

.button-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.add-to-cart-button {
    background-color: #f9d7d9;
    border-radius: 25px;
    padding: 10px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    margin-right: 10px;
    transition: all ease-in-out 0.2s;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
}

.add-to-cart-button:focus {
    outline: none;
}

.add-to-cart-button:hover {
    background-color: var(--color-primary);
    color: #fff;
    font-weight: bolder;
}

.add-to-cart-button:active {
    background-color: var(--color-primary);
    color: #fff;
    font-weight: bolder;
}

.add-to-cart-button svg {
    width: 25px;
    height: auto;
    fill: var(--color-primary);
    transition: fill 0.2s ease-in-out;
}

.add-to-cart-button:hover svg {
    fill: #fff;
}

.add-to-cart-button-title {
    padding-right: 10px;
}

.add-to-cart-button--wait svg {
    animation: rotate-slow 2s infinite;
    fill: #fff;
}

.add-to-cart-button--success svg {
    animation: pop-grow 1s infinite;
}

.quantity-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.quantity-button {
    background-color: rgba(var(--color-primary-rgb), 0.8);
    border: none;
    color: #ffffff;
    font-weight: bolder;
    font-size: 18px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.quantity-button:hover {
    background-color: var(--color-primary);
}

.quantity-display {
    font-size: 16px;
    margin: 0 8px;
    font-weight: bold;
}

@keyframes rotate-slow {
    0% {
        transform: rotate(360deg)
    }

    50% {
        transform: rotate(180deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes pop-grow {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.5)
    }

    100% {
        transform: scale(1)
    }
}

.add-to-cart-container.out-of-stock {
    opacity: 0.7;
    pointer-events: none;
}

.add-to-cart-button.out-of-stock {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.add-to-cart-button[disabled] {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.quantity-button[disabled] {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.shop-closed-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(7px);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 29px;
}

@media only screen and (max-width: 1024px) {
    .shop-info {
        margin: 70px auto 10px;
        padding: 15px;
    }

    .shop-name {
        font-size: 20px;
        margin: 5px 0;
    }

    .rating-value {
        font-size: 20px;
    }

    .star-rating i {
        font-size: 18px;
    }

    .rating-count {
        font-size: 14px;
    }

    .shop-location {
        margin-top: 10px;
    }

    .location-link {
        font-size: 14px;
    }

    .open-status {
        font-size: 12px;
    }

    .timing {
        font-size: 14px;
    }

    .menu-items {
        margin: 45px 15px 130px;
    }

    .menu-item {
        padding: 15px;
    }

    .menu-item h3 {
        font-size: 18px;
    }

    .menu-item p {
        font-size: 14px;
    }

    .dietary-preference {
        font-size: 16px;
    }

    .add-to-cart button {
        padding: 8px 16px;
        font-size: 14px;
        margin-right: 5px;
    }

    .add-to-cart input[type="number"] {
        width: 30px;
    }

    #menu-flters li {
        font-size: 14px;
        padding: 8px 14px 10px 14px;
    }

    .quantity-button {
        padding: 8px;
        font-size: 14px;
        margin: 0 3px;
    }

    .quantity-display {
        font-size: 14px;
        margin: 0 5px;
    }
}


#menu-flters {
    padding: 0;
    margin: 30px auto -30px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
}

#menu-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px 10px 16px;
    font-size: 17px;
    font-weight: 500;
    line-height: 1;
    color: #444444;
    margin: 0 3px 10px 3px;
    transition: all ease-in-out 0.3s;
    background: #fff;
    border: 2px solid var(--color-primary);
    border-radius: 50px;
}

#menu-flters li:hover,
#menu-flters li.filter-active {
    color: #fff;
    background: var(--color-primary);
}

#menu-flters li:last-child {
    margin-right: 0;
}